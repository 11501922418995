* {
  box-sizing: border-box;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModalPortal {
  position: fixed;
  bottom: 0;
  width: 100%;
}